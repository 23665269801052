import Address from '@atoms/Address/Address';
import ContactLink from '@atoms/ContactLink/ContactLink';
import { metaType } from '@lib/proptypes';
import ClockIcon from '@svg/clock.svg';
import PinIcon from '@svg/location-pin.svg';
import UserIcon from '@svg/user.svg';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from './Meta.module.scss';

function Meta({ meta, extraClasses }) {
  const metaEntries = Object.entries(meta);

  if (!metaEntries.length) return null;

  return (
    <ul
      className={classNames(styles.meta, 'u-list-clean u-mb-0', extraClasses)}
    >
      {metaEntries.map(([key, value]) => {
        if (!value) return null;

        if (key === 'hours') {
          let eventHours = [value.start];

          if (value.end) {
            eventHours = [...eventHours, value.end];
          }
          return (
            <li
              key={key}
              className={classNames(styles.metaItem, styles.fullWidth)}
            >
              <ClockIcon />
              {eventHours.join(' - ')}
            </li>
          );
        }

        if (key === 'location') {
          return (
            <li
              key={key}
              className={classNames(styles.metaItem, styles.fullWidth)}
            >
              <PinIcon />
              {value}
            </li>
          );
        }

        if (key === 'locationExpanded') {
          const locations = Array.isArray(value) ? value : [value];

          return locations.map(location => (
            <li key={`${key}-${location.id}`} className={styles.metaItem}>
              <Address
                withIcon
                name={location.title}
                address={location.address}
                city={location.city}
                postal={location.postal}
              />
            </li>
          ));
        }

        if (key === 'contact') {
          return (
            <li
              key={key}
              className={classNames(styles.metaItem, styles.contactItem)}
            >
              {!!value.name && (
                <p className={styles.contact}>
                  <UserIcon />
                  {value.name}
                </p>
              )}

              {!!value.phone && (
                <ContactLink
                  link={{
                    phone: value.phone,
                    title: value.phone,
                    isWhatsapp: false,
                  }}
                />
              )}
              {!!value.email && (
                <ContactLink
                  link={{
                    email: value.email,
                    title: value.email,
                  }}
                />
              )}
            </li>
          );
        }

        return null;
      })}
    </ul>
  );
}

Meta.propTypes = {
  meta: metaType.isRequired,
  extraClasses: PropTypes.string,
};

export default Meta;
