import formatDate from '@utils/formatDate';
import PropTypes from 'prop-types';

export default function Time({ date, format, extraClasses }) {
  return (
    <time dateTime={date} className={extraClasses}>
      {formatDate({ date, format })}
    </time>
  );
}

Time.propTypes = {
  date: PropTypes.string.isRequired,
  format: PropTypes.string,
  extraClasses: PropTypes.string,
};
