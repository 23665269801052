import WrapInLink from '@atoms/Button/WrapInLink';
import Tags from '@atoms/Tags/Tags';
import Time from '@atoms/Time/Time';
import { imageType, metaType } from '@lib/proptypes';
import Meta from '@molecules/Meta/Meta';
import classNames from 'classnames';
import Image from 'next/legacy/image';
import PropTypes from 'prop-types';

import styles from './GridItem.module.scss';

function GridItemLink({ title, href, target }) {
  const className = classNames(styles.link, 'u-umbrella-link');

  if (href) {
    return (
      <WrapInLink
        href={href}
        className={className}
        title={title}
        target={target}
      >
        {title}
      </WrapInLink>
    );
  }

  return title;
}

function GridItem({
  highlighted,
  title,
  subtitle,
  description,
  children,
  image,
  href,
  date,
  dateFormat,
  tags,
  modifier,
  itemType,
  meta,
}) {
  let itemTags = tags;
  if (highlighted) {
    itemTags = [
      {
        label: 'Uitgelicht',
        id: 'Uitgelicht',
        modifier: 'highlighted',
      },
      ...tags,
    ];
  }

  return (
    <article
      className={classNames(styles.item, modifier && styles[modifier])}
      itemScope
      itemType={itemType}
    >
      {!!title && (
        <h1
          className={classNames(styles.title, 'u-typography-heading-4')}
          itemProp="name"
        >
          <GridItemLink
            title={title}
            href={href}
            target={modifier === 'iframeItem' ? '_blank' : '_self'}
          />
        </h1>
      )}

      {date && (
        <Time date={date} format={dateFormat} extraClasses={styles.subtitle} />
      )}

      {subtitle && <p className={styles.subtitle}>{subtitle}</p>}

      {description && (
        <p className={styles.description} itemProp="description">
          {description}
        </p>
      )}

      {!!children && <div className={styles.children}>{children}</div>}

      {!!itemTags?.length && (
        <Tags tags={itemTags} extraClasses={styles.tags} />
      )}

      {!!meta && <Meta meta={meta} extraClasses={styles.meta} />}

      <div className={styles.imageContainer}>
        <Image
          src={image.url}
          placeholder="blur"
          blurDataURL={image.placeholder}
          alt=""
          layout="fill"
          objectFit="cover"
          objectPosition="center"
          itemProp="image"
          className={styles.image}
        />
      </div>
    </article>
  );
}

GridItemLink.propTypes = {
  title: PropTypes.string.isRequired,
  href: PropTypes.string,
  target: PropTypes.string,
};

GridItem.propTypes = {
  highlighted: PropTypes.bool,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  description: PropTypes.string,
  date: PropTypes.string,
  dateFormat: PropTypes.string,
  children: PropTypes.node,
  href: PropTypes.string,
  tags: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string.isRequired })
  ),
  meta: metaType,
  image: imageType,
  modifier: PropTypes.string,
  itemType: PropTypes.string,
};

export default GridItem;
