import LocationPinIcon from '@svg/location-pin.svg';
import WorldIcon from '@svg/world.svg';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from './Address.module.scss';

export default function Address({
  name,
  address,
  city,
  postal,
  phone,
  withIcon,
  modifier,
  message,
}) {
  return (
    <>
      <address
        itemProp="address"
        itemScope
        itemType="https://schema.org/PostalAddress"
        className={classNames(
          styles.address,
          'u-typography-heading-5',
          withIcon && styles.withIcon,
          styles[modifier]
        )}
      >
        <LocationPinIcon className={classNames(styles.icon, styles.pin)} />
        <a
          href={`https://maps.google.com/maps?q=${address},${city}`}
          target="_blank"
          className={styles.link}
        >
          {name && (
            <>
              <span itemProp="name">{name}</span> <br />
            </>
          )}
          <span itemProp="streetAddress">{address}</span>
          <br />
          <span itemProp="postalCode">{postal}</span>{' '}
          <span itemProp="addressLocality">{city}</span>
        </a>
        <br />
        {phone ? (
          <a itemProp="telephone" className={styles.link} href={`tel:${phone}`}>
            {phone}
          </a>
        ) : (
          // This spacer is used for the location overview item when phone is not available
          <span className={styles.spacer}> </span>
        )}
      </address>
      {message && (
        <p className={styles.message}>
          <WorldIcon className={classNames(styles.icon, styles.messageIcon)} />
          {message}
        </p>
      )}
    </>
  );
}

Address.propTypes = {
  name: PropTypes.string,
  address: PropTypes.string,
  city: PropTypes.string,
  postal: PropTypes.string,
  phone: PropTypes.string,
  withIcon: PropTypes.bool,
  modifier: PropTypes.string,
  message: PropTypes.string,
};
