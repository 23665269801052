import classNames from 'classnames';
import PropTypes from 'prop-types';

import Tag from './Tag';
import styles from './Tags.module.scss';

function Tags({ tags, extraClasses }) {
  return (
    <ul className={classNames(styles.tags, 'u-list-clean', extraClasses)}>
      {tags.map(({ label, id, modifier }) => (
        <li key={id || label}>
          <Tag title={label} modifier={modifier} />
        </li>
      ))}
    </ul>
  );
}

Tags.propTypes = {
  extraClasses: PropTypes.string,
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      id: PropTypes.string,
    })
  ),
};

export default Tags;
