import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from './Tag.module.scss';

function Tag({ title, modifier, extraClasses }) {
  return (
    <span
      itemProp="keywords"
      className={classNames(styles.tag, styles[modifier], extraClasses)}
    >
      {title}
    </span>
  );
}

Tag.propTypes = {
  title: PropTypes.string.isRequired,
  modifier: PropTypes.string,
  extraClasses: PropTypes.string,
};

export default Tag;
